<template>
  <div class="bgc">
    <div class="nav_wrap container">
      <div class="left">
        <div class="logo">
          <router-link to="/">
            <img src="../assets/login/nav_logo.png" alt="" />
          </router-link>
        </div>
        <div>
          <router-link to="/" active-class="active_class" exact>首页</router-link>
          <router-link to="/course_list/1" active-class="active_class">选课</router-link>
          <a href="https://tk.successkaoyan.com">题库</a>
        </div>
        <div class="search_wrap">
          <el-input v-model="search_value" placeholder="请输入内容" @keyup.enter.native="handleSearch">
            <i @click="handleSearch" slot="suffix" style="" :class="['search_icon','el-input__icon','el-icon-search',search_icon_on?'on':'']"></i>
          </el-input>
        </div>
      </div>
      <div class="right">
        <a href="http://www.successkaoyan.com/special/successapp/" target="blank">
          <div class="app">客户端下载</div>
        </a>

        <div class="my_wrap" v-if="$store.state.logined">
          <i class=""></i>
          <router-link to="/mine/my_course" active-class="active_class">我的课程</router-link>
          <router-link to="" class="userlogo" active-class="active_class"><img :src="$store.state.user.userlogo" alt="" />
            <div class="logout_wrap">
              <router-link to="/mine/my_center">个人中心</router-link>
              <div class="login_out_btn" @click="login_out">退出</div>
            </div>
          </router-link>
        </div>
        <div v-else class="btn_login" @click="$router.push('/login')">
          注册/登录
        </div>
      </div>
    </div>

    <el-dialog title="社科赛斯提醒您" :visible.sync="DialogVisible" width="300px" :show-close="false" center custom-class="login_out_dialog">
      <span>确定要退出吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleLoginOut">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { clearCookie, cookieGO, getCookie } from "@/config/cookie.js";
import api from "@/config/api";
export default {
  data() {
    return {
      search_value: "",
      logined: false,
      my_course_on: null,
      user: {},
      DialogVisible: false,
      search_icon_on: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions(["setUserAndState"]),
    async getData() {
      let islogin = await this.islogin();

      if (islogin) {
        this.logined = true;
        this.user = this.$store.state.user;

        try {
          const res = await api.person_up();
          if (res.code == 0) {
            let old_userInfo = this.$store.state.user;
            old_userInfo.pname = res.result.pname;
            old_userInfo.userlogo = res.result.userlogo;
            cookieGO("userInfo", JSON.stringify(old_userInfo));
            this.setUserAndState(old_userInfo);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        this.logined = false;
        this.user = {};
      }
    },
    handleDownload() {
      let href = "http://www.successkaoyan.com/special/successapp/";
      window.open(href);
    },
    islogin() {
      this.setUserAndState();
      const { logined } = this.$store.state;
      if (logined) {
        return true;
      } else {
        return false;
      }
    },

    login_out() {
      this.DialogVisible = true;
    },
    async handleLoginOut() {
      let res = await api.sign_out();
      this.DialogVisible = false;
      clearCookie("jst");
      clearCookie("logined");
      clearCookie("userInfo");
      this.setUserAndState();
      this.$message({
        message: "退出成功",
        type: "success",
        duration: 500,
        onClose: () => {
          this.$router.push({
            path: `/`,
          });
        },
      });
    },
    handleSearch() {
      var word = (this.search_value+"").replace(/^\s+|\s+$/g, "");
      word = word.replaceAll("/","%2f");
      if (word) {
        if (this.$route.name != "search") {
          this.$router.push({ path: "/search/" + word });
        } else {
          this.$bus.$emit("handleWord", word);
        }
      }else{
         if (this.$route.name) {
           this.$router.push({ path: "/" });
         }
      }
    },
    async studyCookie_submit(){
      if(this.islogin()){
        let studyCookie_data = getCookie('studyCookie')
        if(studyCookie_data&&studyCookie_data!='undefined'){
          let res = await api.study_duration({
            data:studyCookie_data
          })
          if(res.code==0){
            clearCookie("studyCookie");
          }
        }
      }
      
    },
  },
  mounted() {},
  watch: {
    search_value: function (newVal, oldVal) {
      if (newVal) {
        var new_str = newVal.replace(/^\s+|\s+$/g, "");
       
        if (new_str) {
          this.search_icon_on = true;
        } else {
          this.search_icon_on = false;
        }
      }else{
        this.search_icon_on = false;
      }
    },
    $route(to, from) {
      this.studyCookie_submit()
      if (this.$route.name == "search") {
        let word_record = this.$route.params.word;
        this.search_value = word_record;
      } else {
        this.search_value = "";
        this.search_icon_on = false;
      }
    },
  },
};
</script>
<style>
.bgc {
  background-color: #fff;
}
.nav_wrap .left .el-input.el-input--suffix {
  width: 220px;
  height: 40px;
  /* margin-bottom: 9px; */
  /* background: #edeef1 !important;
  border-radius: 20px !important; */
}
.nav_wrap .left .el-input__inner {
  width: 220px;
  height: 40px;
  line-height: 40px;
  background: #edeef1 !important;
  border-radius: 20px !important;
  border: 1px solid #edeef1;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  box-sizing: border-box;
}
.nav_wrap .el-input__icon {
  margin-right: 10px;
}
.search_icon {
  cursor: pointer;
}
</style>

<style lang="less" scoped>
.nav_wrap {
  margin: 0 auto;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  img {
    width: 234px;
    height: 28px;
    // background-color: #ff6700;
  }
  .left {
    display: flex;
    align-items: center;
    .search_wrap {
      margin-left: 40px;
      // cursor: pointer;
    }
    .logo {
      margin-right: 37px;
    }
    a {
      margin-right: 30px;
      font-size: 16px;
      font-weight: 600;
      color: #1f1f1f;
      line-height: 28px;
      position: relative;
    }
    a.active_class::after {
      display: block;
    }
    a:hover {
      color: #479dff;
    }
    a::after {
      content: "";
      display: none;
      width: 22px;
      height: 4px;
      background: #479dff;
      border-radius: 2px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.right {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #1f1f1f;
  line-height: 28px;
  .btn_login {
    margin-left: 30px;
    cursor: pointer;
  }
}
.app {
  cursor: pointer;
  width: 110px;
  height: 29px;
  background: #f96158;
  border-radius: 14px;
  line-height: 29px;
  text-align: center;
  // margin-right: 29px;
  font-size: 16px;
  font-weight: 500;
  color: #fefefe;
  line-height: 28px;
}
.my_wrap {
  display: flex;
  align-items: center;
  a {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-right: 20px;
  }
  a:hover {
    color: #479dff;
  }
  .active_class {
    color: #479dff;
  }
  i {
    display: block;
    width: 1px;
    height: 18px;
    background: #eeeeee;
    margin: 0 12px;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .my_course {
    margin-right: 22px;
  }
}
.userlogo:hover .logout_wrap {
  display: flex;
}

.logout_wrap {
  position: absolute;
  width: 155px;
  height: 123px;
  // background-color: #fff;
  background: url(../assets/login/logout_bg.png) no-repeat;
  background-size: contain;
  // box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.13);
  // border-radius: 10px;
  z-index: 100;
  top: 46px;
  right: 0;
  padding-top: 26px;
  box-sizing: border-box;
  display: none;
  align-items: center;
  flex-direction: column;
  a,
  .login_out_btn {
    margin: 0 auto;
    display: block;
    height: 40px;
    width: 99px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
    border-bottom: 1px solid #e9e9e9;
  }
  .login_out_btn {
    border-bottom: none;
  }
  a:hover,
  .login_out_btn:hover {
    color: #479dff;
  }

  // .logout_wrap::after {
  //   // width: 0;
  //   // height: 0;
  //   // border-left: 50px solid transparent;
  //   // border-right: 50px solid transparent;
  //   // border-bottom: 100px solid red;
  //   content: "";
  //   width: 100px;
  //   height: 100px;
  //   display: block;
  //   background-color: #000;
  //   position: absolute;
  //   top: 100px;
  //   right: 0;
  // }
}
.el-icon-search.on {
  color: #479dff;
  font-weight: bold;
}
/deep/.login_out_dialog{
  border-radius: 10px;
}
</style>