import Vue from "vue";
import VueRouter from "vue-router";
import { getCookie } from "@/config/cookie";

// 防止重复点击路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/index.vue"),
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../views/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/course_list/:id",
    name: "course_list",
    component: () => import("../views/course_list.vue"),
  },

  {
    //课程详情
    path: "/course_info/:course_id",
    name: "course_info",
    // meta: {
    //   keepAlive: true, //此组件需要被缓存
    // },
    component: () => import("../views/course_info.vue"),
  },
  {
    // 课程观看
    path: "/course_watch/:course_id/:chapter_id?/:sec_id?",
    name: "course_watch",
   
    component: () => import("../views/course_watch.vue"),
  },
  {
    // web-view
    path: "/agreement/:id?",
    name: "agreement",
    component: () => import("../views/web-view.vue"),
  },
  {
    //订单页
    // path: '/order_info/:course_id',
    path: "/order_info/:good",
    name: "order_info",
    component: () => import("../views/order_info.vue"),
  },
  {
    //支付页  参数 {oid,price}
    path: "/pay/:p",
    name: "pay",
    component: () => import("../views/pay.vue"),
  },
  {
    //支付成功页  参数 oid
    path: "/pay_success/:o",
    name: "pay_success",
    component: () => import("../views/pay_success.vue"),
  },
  {
    //搜索页  参数 word
    path: "/search/:word",
    name: "search",
    component: () => import("../views/search.vue"),
  },
  {
    //测试页
    path: "/ceshi",
    name: "ceshi",
    component: () => import("../views/ceshi.vue"),
  },

  {
    path: "/mine/mine",
    name: "mine",
    component: () => import("../views/mine/mine.vue"),
    children: [
      //我的个人中心
      {
        path: "/mine/my_center",
        name: "center",
        component: () => import("../views/mine/my_center.vue"),
      },
      {
        //我的课程
        path: "/mine/my_course",
        name: "my_course",
        component: () => import("../views/mine/my_course.vue"),
      },
      //  我的订单
      {
        path: "/mine/my_order",
        name: "my_order",
        component: () => import("../views/mine/my_order.vue"),
      },
      //  我的订单详情
      {
        path: "/mine/my_order_detail/:id",
        name: "my_order_detail",
        component: () => import("../views/mine/my_order_detail.vue"),
      },
      //  我的兑换码
      {
        path: "/mine/change_code",
        name: "change_code",
        component: () => import("../views/mine/change_code.vue"),
      },
      //   我的优惠
      {
        path: "/mine/my_coupon",
        name: "my_coupon",
        component: () => import("../views/mine/my_coupon.vue"),
      },
      {
        //地址
        path: "/mine/my_address",
        name: "my_address",
        component: () => import("../views/mine/my_address.vue"),
      },
      {
        //意见反馈
        path: "/mine/feedback",
        name: "feedback",
        component: () => import("../views/mine/feedback.vue"),
      },
      {
        //帮助中心
        path: "/mine/help_center",
        name: "help_center",
        component: () => import("../views/mine/help_center.vue"),
      },
      {
        //帮助详情
        path: "/mine/help_detail",
        name: "help_detail",
        component: () => import("../views/mine/help_detail.vue"),
      },
      {
        //帮助详情
        path: "/mine/account_settings",
        name: "account_settings",
        component: () => import("../views/mine/account_settings.vue"),
      },
      {
        //我的模考记录
        path: "/mine/mock_record",
        name: "mock_record",
        component: () => import("../views/mine/my_mockrecord.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }), // 页面进入第二页回到顶部
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//拦截器
router.beforeEach((route, redirect, next) => {
  // console.log(route)
  if (
    route.name == "course_watch" ||
    route.name == "center" ||
    route.name == "my_course" ||
    route.name == "my_order" ||
    route.name == "change_code" ||
    route.name == "my_coupon" ||
    route.name == "my_address" ||
    route.name == "account_settings"||
    route.name == "feedback"
  ) {
    if (!getCookie("logined")) {
      next({
        path: "/login",
        query: { re_url: route.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
