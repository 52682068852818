import cookies from 'js-cookie'
/**
 * 存储tokens
 * @param {string} accessToken
 * @param {string} refreshToken
 */
export function cookieGO(key, value, time) {
  // 存储tokens tokens只进入cookies，不进入vuex全局管理
  if (time) {
    var time_t = 1000 * time
  } else {
    var time_t = 1000 * 60 * 60 * 24
  }
  var today = new Date();
  var expires = new Date();
  expires.setTime(today.getTime() + time_t);
  // escape(value) 
  window.document.cookie =
    key + '=' + escape(value) + ('; expires=' + expires.toGMTString()) +
    ';domain=.successkaoyan.com;path=/';
}

/**
 * 存储access_token
 * @param {string} accessToken
 */
// export function saveAccessToken(accessToken) {
//   cookies.set('access_token', `${accessToken}`)
// }

/**
 * 获得某个token
 * @param {string} tokenKey
 */
export function getCookie(name) {
  var findcookie = name + '=';
		if (window.document.cookie.length > 0) {
			// if there are any cookies
			let offset = window.document.cookie.indexOf(findcookie);
			if (offset != -1) {
				// if cookie exists
				offset += findcookie.length; // set index of beginning of value
				let end = window.document.cookie.indexOf(';', offset); // set index of end of cookie value
				if (end == -1) end = window.document.cookie.length;
				return unescape(window.document.cookie.substring(offset, end));
        // return window.document.cookie.substring(offset, end);
			}
		}
		return null;
  // return cookies.get(tokenKey)
}

/**
 * 移除token
 */
export function clearCookie(key) {
  var d = new Date();
		d.setTime(d.getTime() + (-1 * 24 * 60 * 60 * 1000));
		window.document.cookie =
    key + '=""' + ('; expires=' + d.toGMTString()) +
			';domain=.successkaoyan.com;path=/';
  // cookies.remove('access_token')
  // cookies.remove('refresh_token')
  // sessionStorage.removeItem('flag')
  // sessionStorage.clear()
  // localStorage.clear()
}

// 升序
export function compare(property){
  return function(a, b) {
    var value1 = a[property];
    var value2 = b[property];
    return value1 - value2;
  }
}
// 降序
export function compare_down(property){
  return function(a, b) {
    var value1 = a[property];
    var value2 = b[property];
    return value2 - value1;
  }
}

/*
     ** randomWord 产生任意长度随机字母数字组合
     ** randomFlag-是否任意长度 min-任意长度最小位[固定位数] max-任意长度最大位
     */
     export function randomWord(randomFlag, min, max) {
      var str = '',
        range = min,
        arr = [
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          'a',
          'b',
          'c',
          'd',
          'e',
          'f',
          'g',
          'h',
          'i',
          'j',
          'k',
          'l',
          'm',
          'n',
          'o',
          'p',
          'q',
          'r',
          's',
          't',
          'u',
          'v',
          'w',
          'x',
          'y',
          'z',
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
          'M',
          'N',
          'O',
          'P',
          'Q',
          'R',
          'S',
          'T',
          'U',
          'V',
          'W',
          'X',
          'Y',
          'Z',
        ]
      // 随机产生
      if (randomFlag) {
        range = Math.round(Math.random() * (max - min)) + min
      }
      for (var i = 0; i < range; i++) {
        var pos = Math.round(Math.random() * (arr.length - 1))
        str += arr[pos]
      }
      return str
    }