import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import * as types from './mutation-type'

import {
  getCookie
} from '@/config/cookie.js'

const store = new Vuex.Store({
  state: {
    logined: false, // 是否登录
    user: {}, // 当前用户
    emotionDataIndexs_data: {
      '[笑容]': require('@/assets/course/emotion/emoji_0.png'),
      '[担心]': require('@/assets/course/emotion/emoji_1.png'),
      '[保守秘密]': require('@/assets/course/emotion/emoji_2.png'),
      '[鄙视]': require('@/assets/course/emotion/emoji_3.png'),
      '[闭眼接吻]': require('@/assets/course/emotion/emoji_4.png'),
      '[财迷]': require('@/assets/course/emotion/emoji_5.png'),
      '[灿烂的笑脸]': require('@/assets/course/emotion/emoji_6.png'),
      '[沉睡]': require('@/assets/course/emotion/emoji_7.png'),
      '[打喷嚏]': require('@/assets/course/emotion/emoji_8.png'),
      '[戴口罩]': require('@/assets/course/emotion/emoji_9.png'),
      '[颠倒的笑]': require('@/assets/course/emotion/emoji_10.png'),
      '[恶心]': require('@/assets/course/emotion/emoji_11.png'),
      '[发怒]': require('@/assets/course/emotion/emoji_12.png'),
      '[翻白眼]': require('@/assets/course/emotion/emoji_13.png'),
      '[飞吻]': require('@/assets/course/emotion/emoji_14.png'),
      '[愤怒]': require('@/assets/course/emotion/emoji_15.png'),
      '[尴尬]': require('@/assets/course/emotion/emoji_16.png'),
      '[搞笑]': require('@/assets/course/emotion/emoji_17.png'),
      '[鬼脸]': require('@/assets/course/emotion/emoji_18.png'),
      '[含泪笑]': require('@/assets/course/emotion/emoji_19.png'),
      '[昏昏欲睡]': require('@/assets/course/emotion/emoji_20.png'),
      '[狡黠]': require('@/assets/course/emotion/emoji_21.png'),
      '[惊讶]': require('@/assets/course/emotion/emoji_22.png'),
      '[纠结]': require('@/assets/course/emotion/emoji_23.png'),
      '[沮丧]': require('@/assets/course/emotion/emoji_24.png'),
      '[恳求]': require('@/assets/course/emotion/emoji_25.png'),
      '[恐惧尖叫]': require('@/assets/course/emotion/emoji_26.png'),
      '[哭泣]': require('@/assets/course/emotion/emoji_27.png'),
      '[哭笑]': require('@/assets/course/emotion/emoji_28.png'),
      '[酷]': require('@/assets/course/emotion/emoji_29.png'),
      '[脸红]': require('@/assets/course/emotion/emoji_30.png'),
      '[流口水]': require('@/assets/course/emotion/emoji_31.png'),
      '[呕吐]': require('@/assets/course/emotion/emoji_32.png'),
      '[疲惫]': require('@/assets/course/emotion/emoji_33.png'),
      '[伤心]': require('@/assets/course/emotion/emoji_34.png'),
      '[失望]': require('@/assets/course/emotion/emoji_35.png'),
      '[释然]': require('@/assets/course/emotion/emoji_36.png'),
      '[受伤]': require('@/assets/course/emotion/emoji_37.png'),
      '[说谎]': require('@/assets/course/emotion/emoji_38.png'),
      '[贪吃]': require('@/assets/course/emotion/emoji_39.png'),
      '[探究]': require('@/assets/course/emotion/emoji_40.png'),
      '[调皮]': require('@/assets/course/emotion/emoji_41.png'),
      '[痛苦]': require('@/assets/course/emotion/emoji_42.png'),
      '[头晕]': require('@/assets/course/emotion/emoji_43.png'),
      '[捂嘴]': require('@/assets/course/emotion/emoji_44.png'),
      '[嘘]': require('@/assets/course/emotion/emoji_45.png'),
      '[眼里有爱]': require('@/assets/course/emotion/emoji_46.png'),
      '[拥抱]': require('@/assets/course/emotion/emoji_47.png'),
      '[晕的笑脸]': require('@/assets/course/emotion/emoji_48.png'),
      '[震惊]': require('@/assets/course/emotion/emoji_49.png'),
      '[蛋糕]': require('@/assets/course/emotion/emoji_50.png'),
      '[枯萎]': require('@/assets/course/emotion/emoji_51.png'),
      '[礼花]': require('@/assets/course/emotion/emoji_52.png'),
      '[礼物]': require('@/assets/course/emotion/emoji_53.png'),
      '[玫瑰]': require('@/assets/course/emotion/emoji_54.png'),
      '[强]': require('@/assets/course/emotion/emoji_55.png'),
      '[胜利]': require('@/assets/course/emotion/emoji_56.png'),
      '[双手合十]': require('@/assets/course/emotion/emoji_57.png'),
      '[赞]': require('@/assets/course/emotion/emoji_58.png'),
      '[嘴]': require('@/assets/course/emotion/emoji_59.png'),
    },
    custom_emotions_data: {
      "0": require("@/assets/course/custom/live_emoji_00.gif"),
      "1": require("@/assets/course/custom/live_emoji_01.gif"),
      "2": require("@/assets/course/custom/live_emoji_02.gif"),
      "3": require("@/assets/course/custom/live_emoji_03.gif"),
      "4": require("@/assets/course/custom/live_emoji_04.gif"),
      "5": require("@/assets/course/custom/live_emoji_05.gif"),
      "6": require("@/assets/course/custom/live_emoji_06.gif"),
      "7": require("@/assets/course/custom/live_emoji_07.gif"),
      "8": require("@/assets/course/custom/live_emoji_08.gif"),
      "9": require("@/assets/course/custom/live_emoji_09.gif"),
      "10": require("@/assets/course/custom/live_emoji_10.gif"),
      "11": require("@/assets/course/custom/live_emoji_11.gif"),
      "12": require("@/assets/course/custom/live_emoji_12.gif"),
      "13": require("@/assets/course/custom/live_emoji_13.gif"),
      "14": require("@/assets/course/custom/live_emoji_14.gif"),
      "15": require("@/assets/course/custom/live_emoji_15.gif")
    },
    help_data:JSON.parse(sessionStorage.getItem('helpCenterData'))||{}
  },
  mutations: {
    [types.SET_LOGINED](state, payload) {
      if (payload) {
        state.logined = payload;
      } else {
        if (getCookie('logined')) {
          state.logined = getCookie('logined');
        } else {
          state.logined = false
        }
      }
    },
    [types.SET_USER](state, payload) {
      if (payload) {
        state.user = payload;
      } else {
        if (getCookie('userInfo')) {
          state.user = JSON.parse(getCookie('userInfo'));
        } else {
          state.user = {}
        }
      }
    },
    [types.SET_COLUMN](state, payload) {
      if (payload) {
        state.column = payload;
        localStorage.setItem('column', JSON.stringify(payload))
      } else {
        if (localStorage.getItem('column')) {
          state.column = JSON.parse(localStorage.getItem('column'));
        }
      }
    },
    [types.SET_HELP_DATA](state, payload) {
			if (payload) {
				state.help_data = payload;
        sessionStorage.setItem('helpCenterData',JSON.stringify(payload));
			} else {
				state.help_data = {}
        sessionStorage.setItem('helpCenterData',JSON.stringify({}));
			}
		}
  },
  actions: {
    setUserAndState({
      commit
    }, user) {
      if (user) {
        commit(types.SET_LOGINED, true)
      } else {
        commit(types.SET_LOGINED, false)
      }
      commit(types.SET_USER, user)
    },
    setColumn({
      commit
    }, data) {
      commit(types.SET_COLUMN, data)
    },
    setHelpData({
			commit
		}, data) {
			commit(types.SET_HELP_DATA, data)
		},
  },
})
export default store
