import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2'
import preventReClick from './config/preventReClick' //防多次点击，重复提交

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(VueClipboard)

Vue.prototype.$bus = new Vue() 

import TIM from "tim-js-sdk";
let options = {
  SDKAppID: 1400358638,
};
let tim = TIM.create(options);
tim.setLogLevel(1);
window.tim = tim
window.TIM = TIM
Vue.prototype.tim =tim 
Vue.prototype.TIM =TIM 

import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

// // 引入错误处理插件
// import ErrorPlugin from './config/errorPlugin'
// Vue.use(ErrorPlugin)

// import debug from './config/debug';
// Vue.use(debug);

new Vue({
  router,
  store,
  preventReClick,
  render: h => h(App)
}).$mount('#app')
