import { get, post } from './axios'

class API {
    // 登录      type  1验证码   2密码
    login(data, issign) {
        return post('home/login/login_res', data, issign);
    }
    // 获取验证码   type  验证码登录  1     重置密码短信  2
    send_sms(data, issign) {
        return post('home/login/send_sms', data, issign);
    }
    // 语音验证码   type  验证码登录  1     重置密码短信  2
    send_vms(data, issign) {
        console.log('语音验证码'+data)
        // return post('home/login/send_vms', data, issign);
    }
    // 重置密码  
    reset_pwd(data, issign) {
        return post('home/login/reset_pwd', data, issign);
    }
    // 退出
    sign_out(data, issign) {
        return post('home/login/sign_out', data, issign);
    }
    // 注销
    log_out(data, issign) {
        return post('home/login/log_out', data, issign);
    }
    // 扫码登录 获取url
    login_code(data, issign) {
        return post('home/login/login_code', data, issign);
    }
    // 扫码登录 是否登录
    polling(data, issign) {
        return post('home/login/polling', data, issign);
    }


    // 首页
    home(data) {
        return post(`home`, data)
    }
    // 首页2
    mate(data, issign) {
        return post('home/mate', data, issign);
    }
    // 课程详情页
    detail(data, issign) {
        return post('home/course/detail', data, issign);
    }
    // 获取视频签名
    ticket(data, issign) {
        return post('home/course/ticket', data, issign);
    }
    // 全部课程
    all_course(data, issign) {
        return post('home/course/all_course', data, issign);
    }
    // 观看课程 提交学习记录
    study(data, issign) {
        return post('home/course/study', data, issign);
    }
    // 观看课程 提交学习记录(试听)
    try_listen(data, issign) {
        return post('home/course/try_listen', data, issign);
    }
    // 观看课程 提交学习记录
    study_duration(data, issign) {
        return post('home/course/study_duration', data, issign);
    }
    // 课程是否加入
    is_sign(data, issign) {
        return post('home/course/is_sign', data, issign);
    }
    // 直播 info
    live_info(data, issign) {
        return post('home/live/info', data, issign);
    }
    // 直播 增加直播间人气
    census(data, issign) {
        return post('home/live/census', data, issign);
    }
    // 直播 确认公告
    confirm(data, issign) {
        return post('home/live/confirm', data, issign);
    }
    // 直播 确认公告 人员列表
    confirm_list(data, issign) {
        return post('home/live/confirm_list', data, issign);
    }
    // 作业 获取详情
    homework_detail(data, issign) {
        return post('home/personal/task_detail', data, issign);
    }
    // 作业 提交
    task_submit(data, issign) {
        return post('home/personal/task', data, issign);
    }
    // 作业 提交
    task_list(data, issign) {
        return post('home/personal/task_list', data, issign);
    }

    // 地址
    address_List(data, issign) {
        return post('home/personal/address', data, issign);
    }
    // 地址添加
    add_address(data, issign) {
        return post('home/personal/add_address', data, issign);
    }
    // 地址编辑
    edit_address(data, issign) {
        return post('home/personal/edit_address', data, issign);
    }
    // del_address
    del_address(data, issign) {
        return post('home/personal/del_address', data, issign);
    }
    // 意见反馈
    add_feedback(data, issign) {
        return post('home/personal/add_feedback', data, issign);
    }
    // 兑换卡
    exchange(data, issign) {
        return post('home/personal/exchange', data, issign);
    }
    // 帮助中心
    help_list(data, issign) {
        return post('home/personal/help_list', data, issign);
    }
    // 我的优惠券
    my_coupon(data, issign) {
        return post('home/personal/my_coupon', data, issign);
    }
    // 我的订单
    my_order(data, issign) {
        return post('home/personal/my_order', data, issign);
    }
    //	订单详情
    order_info(data, issign) {
        return post('home/personal/order_info', data, issign);
    }
    // 我的 直播
    my_live(data, issign) {
        return post('home/personal/my_live', data, issign);
    }
    // 我的 课程
    my_course(data, issign) {
        return post('home/personal/my_course', data, issign);
    }
    // 确认订单页
    order_detail(data, issign) {
        return post('home/order/detail', data, issign);
    }
    //  热门专业
    red_major(data, issign) {
        return post('home/discover/major', data, issign);
    }
    // 个人信息
    personal_data(data, issign) {
        return post('home/personal/my_info', data, issign);
    }
    // 我的模考记录
    mymock_data(data, issign) {
        return post('home/personal/mock_record', data, issign);
    }

    // 更新个人信息
    save_info(data, issign) {
        return post('home/personal/save_info', data, issign);
    }
    // 提交订单
    enter(data, issign) {
        return post('home/order/enter', data, issign);
    }
    // 立即支付
    order_pay(data, issign) {
        return post('home/order/p_pay', data, issign);
    }
    // 判断是否支付
    is_pay(data, issign) {
        return post('home/order/is_pay', data, issign);
    }
    // 详情页推荐
    recom(data, issign) {
        return post('home/course/recom', data, issign);
    }

    // 支付成功获取数据
    pay_success(data, issign) {
        return post('home/order/market', data, issign);
    }
    // 搜索
    search(data, issign) {
        return post('home/course/search', data, issign);
    }
    // 再次支付
    again_pay(data, issign) {
        return post('home/order/again_pay', data, issign);
    }
    // 更新用户信息
    person_up(data, issign) {
        return post('home/personal/info', data, issign);
    }
    // 直播im 重连
    re_live(data, issign) {
        return post('home/live/re_live', data, issign);
    }
    // im 重置
    reset_im(data, issign) {
        return post('home/login/reset_im', data, issign);
    }
    // 错误信息上报
    errorMsg(data, issign) {
        return post('home/other/web_error', data, issign);
    }
    // 立即报名
    sing_up(data, issign) {
        return post('home/course/sing_up', data, issign);
    }
    // socket
    socket_error(data, issign) {
        return post('home/other/socket_log', data, issign);
    }
    // 线下课程已购买营销提示
    course_market(data, issign) {
        return post('home/order/course_market', data, issign);
    }
}

export default new API()