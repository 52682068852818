<template>
  <!-- <el-scrollbar> -->
  <div id="app">
    <nav_top v-show="common_show" />
    <!-- v-if="$route.meta.keepAlive" -->
    <transition name="el-fade-in-linear" mode="out-in">
      <!-- <keep-alive v-if="$route.meta.keepAlive">
        <router-view v-on:public_header="public_header"></router-view>
      </keep-alive> -->
      <router-view v-on:public_header="public_header"></router-view>
    </transition>

    <footer_ v-show="common_show" />
  </div>
  <!-- </el-scrollbar> -->
</template>

<script>
import footer_ from "@/components/footer_.vue";
import nav_top from "@/components/nav_top.vue";
export default {
  components: {
    footer_,
    nav_top,
  },
  data() {
    return {
      common_show: true,
    };
  },
  mounted() {
    let _this = this;

    // this.$bus.$on("loginIm",  (data)=> {
    //   console.log('111111111111111111111111')
    //   this.tim
    //     .login(data)
    //     .then(function (imResponse) {
    //       console.log(imResponse.data); // 登录成功
    //       if (imResponse.data.repeatLogin === true) {
    //         // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
    //         // console.log(imResponse.data.errorInfo);
    //       }
    //     })
    //     .catch(function (imError) {
    //       console.warn("login error:", imError); // 登录失败的相关信息
    //     });

    //   let onSdkReady = function ({name}) {
    //       let promise = _this.tim.getMyProfile();
    //       promise.then(function(imResponse) {
    //         console.log(imResponse.data); // 个人资料 - Profile 实例
    //       }).catch(function(imError) {
    //         console.warn('getMyProfile error:', imError); // 获取个人资料失败的相关信息
    //       });
    //   };
    //   this.tim.on(TIM.EVENT.SDK_READY, onSdkReady);
    // });
  },
  methods: {
    public_header(bool) {
      this.common_show = bool;
    },
  },
};
</script>
<style lang="less">
*,
body {
  margin: 0;
  padding: 0;
}
// html,
// body {
//   &::-webkit-scrollbar {
//     width: 0px;
//   }
// }
#app {
  font-family: "Arial", "Hiragino Sans GB", "微软雅黑", "Helvetica",
    "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  min-width: 1200px;
  margin: 0 auto;
  text-align: center;
  min-height: 100vh;
  position: relative;
  background-color: #f5f6f8;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
.nocontent {
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 180px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    color: #737373;
  }
}

.scroll {
  height: 100px;
  overflow-y: hidden;
}
.el-scrollbar {
  height: 100%;
}
.el-scrollbar__bar {
}
.el-scrollbar__wrap {
  overflow-y: auto;
  overflow-x: hidden;
}
.el-scrollbar__thumb {
  /* 可设置滚动条颜色 */
  // background-color: #479dff !important;
}
.taj {
  text-align: justify;
}
.tac {
  text-align: center;
}
.letterspace1{
  letter-spacing: 1px;
}
</style>
