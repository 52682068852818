import { getCookie } from "./cookie.js";
let debuggerfun = function () {
  window.onload = function () {
    //屏蔽键盘事件
    document.onkeydown = function () {
      var e = window.event || arguments[0];
      //F12
      if (e.keyCode == 123) {
        return false;
        //Ctrl+Shift+I
      } else if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
        return false;
        //Shift+F10
      } else if (e.shiftKey && e.keyCode == 121) {
        return false;
        //Ctrl+U
      } else if (e.ctrlKey && e.keyCode == 85) {
        return false;
        //Ctrl+P
      } else if (e.ctrlKey && e.keyCode == 80) {
        return false;
        //Ctrl+Shift+P
      } else if (e.ctrlKey && e.shiftKey && e.keyCode == 80) {
        return false;
      }
    };
    //屏蔽⿏标右键
    document.oncontextmenu = function () {
      return false;
    };
    // 如果打开f12，添加debugger
    setInterval(function () {
      check();
    }, 2000);
    var check = function () {
      function doCheck(a) {
        if (("" + a / a)["length"] !== 1 || a % 20 === 0) {
          (function () {}["constructor"]("debugger")());
        } else {
          (function () {}["constructor"]("debugger")());
        }
        doCheck(++a);
      }

      try {
        doCheck(0);
      } catch (err) {}
    };
    check();
  };
};
let host = "";
let tk_host = "";
if (process.env.NODE_ENV === "development") {
  // 开发环境
  // host = 'https://success-cloud.successkaoyan.com/';
  host = "https://tsuccess-cloud.successkaoyan.com/";
  tk_host = "https://ltk.successkaoyan.com:8081/";
} else {
  // 生成环境
  // host = "https://tsuccess-cloud.successkaoyan.com/";
  // tk_host = "https://ttk.successkaoyan.com/";

  host = "https://success-cloud.successkaoyan.com/";
  tk_host = "https://tk.successkaoyan.com/";

  let userInfo = getCookie("userInfo");
  if (userInfo) {
    let id = JSON.parse(userInfo).id;
    if (id == 11 || id == 22) {
    } else {
      debuggerfun();
    } 
  }
}
export { host, tk_host };
