<template>
  <transition name="el-fade-in-linear" mode="out-in">
    <div class="footer" ref="footer">
      <div class="container">
        <div class="top">
          <img class="logo" src="../assets/login/foot_logo.png" alt="" />
          <ul>
            <li>关于社科</li>
            <a
              href="http://www.successkaoyan.com/special/intosuccess/"
              target="_blank"
            >
              <li>关于社科</li>
            </a>
            <a
              href="http://www.successkaoyan.com/special/successapp/"
              target="_blank"
            >
              <li>APP下载</li>
            </a>
            <a href="http://www.successkaoyan.com/sitemap.html" target="_blank">
              <li>网站地图</li>
            </a>
          </ul>
          <ul>
            <li>常见问题</li>
            <router-link to="/mine/feedback" target="_blank">
              <li>意见反馈</li>
            </router-link>
            <router-link to="/mine/help_center?item=7" target="_blank">
              <li>常见问题</li>
            </router-link>
            <router-link to="/agreement/0" target="_blank">
              <li>用户协议</li>
            </router-link>
            <router-link to="/agreement/1" target="_blank">
              <li>隐私政策</li>
            </router-link>
          </ul>
          <ul>
            <li>网站产品</li>
            <a
              href="http://www.successkaoyan.com/special/book/"
              target="_blank"
            >
              <li>图书购买</li>
            </a>
            <a href="https://mwk.yanxian.org/" target="_blank">
              <li>考研资料</li>
            </a>
            <a href="http://yuanxiao.mbaschool.com.cn/" target="_blank">
              <li>院校库</li>
            </a>
            <a href="http://www.successkaoyan.com/html/kydt/" target="_blank">
              <li>考研资讯</li>
            </a>
          </ul>
          <ul>
            <li>考研题库</li>
            <a :href="`${tk_host}list/2`" target="_blank">
              <li>历年真题</li>
            </a>
            <a :href="`${tk_host}list/1`" target="_blank">
              <li>模考大赛</li>
            </a>
            <!-- <li @click="go_web('http://tk.yanxian.org/')">模拟试卷</li> -->
            <!-- <li @click="go_web('http://tk.yanxian.org/')">专项练习</li> -->
          </ul>
          <div class="r_wx">
            <div class="wx_item">
              <img src="../assets/login/wxgzh.png" alt="" />
              <p class="tac">社科赛斯服务号</p>
            </div>
            <div class="wx_item">
              <img
                src="https://success-image.successkaoyan.com/download_app.png"
                alt=""
              />
              <p class="tac">
                社科赛斯考研 <br />
                APP下载
              </p>
            </div>
          </div>
        </div>
        <div class="text_ tac">
          <div class="tag-wrap">
            友情链接：
            <a href="http://www.yanxian.org" target="_blank">
              <span>考研网</span>
            </a>
            <!-- |
            <a href="http://www.dangbei.com/" target="_blank">
              <span>当贝市场 </span>
            </a> -->
          </div>
          <p>
            <a
              href="http://www.successkaoyan.com/html/successzx/13003.html"
              target="_blank"
            >
              <span
                >中华人民共和国出版物经营许可证 新出发京批字第直200383号</span
              >
            </a>
            |
            <a
              href="http://www.successkaoyan.com/html/successzx/13003.html"
              target="_blank"
            >
              <span>中关村高新技术企业 编号:20202010222501</span>
            </a>
          </p>
          <p>
            <a
              href="http://www.successkaoyan.com/html/successzx/13003.html"
              target="_blank"
            >
              <span> 增值电信业务经营许可证 编号:京B2-20181061 </span>
            </a>
            |
            <a
              href="http://www.successkaoyan.com/html/successzx/13003.html"
              target="_blank"
            >
              <span> 高新技术企业证书 编号:GR201911007960</span>
            </a>
            |
            <a
              href="http://www.successkaoyan.com/html/successzx/13003.html"
              target="_blank"
            >
              <span> 广播电视节目制作经营许可证编号:(京)字第18408号 </span>
            </a>
          </p>
          <p>
            <a href="https://beian.miit.gov.cn/" target="_blank">
              <span
                >京ICP备05047554号-21 Copyright © 2002-{{
                  new Date().getFullYear()
                }}</span
              >
            </a>
            北京社科赛斯教育科技有限公司版权所有
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { tk_host } from "@/config/config.js";
export default {
  name: "footer_",
  props: {},
  data() {
    return {
      tk_host: "",
    };
  },
  methods: {
    // 到外部网页
    // go_web(url) {
    // parent.location.href = url;
    // window.open(url);
    // },
    // 到内部网页
    // go_mine(url) {
    //   if (url) {
    //     let { href } = this.$router.resolve(url);
    //     window.open(href, "_blank");
    //   }
    // },
  },
  created() {
    this.tk_host = tk_host;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.footer {
  // width: 1920px;
  height: 333px;
  background: #202838;
  padding: 30px 0 18px;
  position: absolute;
  bottom: -382px;
  left: 0;
  right: 0;

  .top {
    border-bottom: 1px solid #888e99;
    padding-bottom: 20px;
    display: flex;
    // align-items: flex-start;
    .logo {
      width: 234px;
      height: 28px;
      margin-right: 58px;
      margin-top: 57px;
    }
    ul {
      margin: 0;
      padding: 0;
      text-align: left;
      width: 200px;
      & > li:nth-of-type(1) {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }
      a {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #888e99;
        line-height: 28px;
        margin-bottom: 8px;
        width: fit-content;
      }
      & > li {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #888e99;
        line-height: 28px;
        margin-bottom: 8px;
        width: fit-content;
        // a {
        //   cursor: pointer;
        //   font-size: 14px;
        //   font-weight: 400;
        //   color: #888e99;
        //   line-height: 28px;
        //   margin-bottom: 8px;
        // }
      }
      li:hover {
        color: #fff;
      }
    }
    .r_wx {
      display: flex;
      // align-items: flex-start;
      .wx_item {
        margin-left: 24px;
        img {
          width: 110px;
          height: 110px;
        }
        p {
          margin-top: 7px;
          font-size: 14px;
          font-weight: 400;
          color: #888e99;
          line-height: 16px;
        }
      }
    }
  }
  .text_ {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #888e99;
    line-height: 28px;
    a {
      font-size: 14px;
      font-weight: 400;
      color: #888e99;
    }
  }
  .text_ span:hover {
    color: #fff;
    cursor: pointer;
  }
}
</style>
